import { gsap } from "gsap";

export default class Flower {
    constructor(element) {
        this.element = element;
        this.parent = this.element.parentNode;
        this.id = this.parent.getAttribute("data-id");
        this.p = 0;
        this.ctx = null;
        this.r = 0;
        this.animated = false;
        this.delayNrw = this.parent.getAttribute("data-delay_nrw") ? parseFloat(this.parent.getAttribute("data-delay_nrw")) : 0;
        this.delay = this.parent.getAttribute("data-delay") ? parseFloat(this.parent.getAttribute("data-delay")) : 0;
        this.init();
    }

    init() {
        this.ctx = this.element.getContext("2d");
        this.setSize();
        this.draw();
    }

    setSize() {
        this.p = this.parent.offsetWidth * Math.max(2, window.devicePixelRatio);
        this.element.width = this.p;
        this.element.height = this.p;
    }

    animate() {
        const loop = () => {
            if (!this.animated) {
                gsap.to(this, {
                    r: 0.6,
                    duration: 1,
                    delay: window.innerWidth >= 835 ? this.delay : this.delayNrw,
                    ease: "circ.inOut",
                    onComplete: () => {
                        this.animEnd1 = true;
                        this.r = 0.01;
                    },
                });
                this.animated = true;
            }

            if (this.animEnd1 && !this.animEnd2) {
                gsap.to(this, {
                    r: 0.6,
                    duration: 1,
                    delay: 0,
                    ease: "circ.inOut",
                    onComplete: () => {
                        this.end = true;
                    },
                });
                this.animEnd2 = true;
            }

            this.draw();

            if (!this.end) {
                requestAnimationFrame(loop);
            }
        };

        loop();
    }

    run() {
        if (!this.animated) {
            gsap.to(this, {
                r: 0.6,
                duration: 1,
                delay: 1,
                ease: "circ.inOut",
                onComplete: () => {
                    this.animEnd1 = true;
                    this.r = 0.01;
                },
            });
            this.animated = true;
        }

        if (this.animEnd1 && !this.animEnd2) {
            gsap.to(this, { r: 0.6, duration: 1, delay: 0, ease: "circ.inOut" });
            this.animEnd2 = true;
        }

        this.draw();
    }

    onResize() {
        this.setSize();
        this.draw();
    }

    draw() {
        const ctx = this.ctx;
        const p = this.p;

        //clear
        ctx.clearRect(0, 0, ctx.width, ctx.height);
        ctx.globalCompositeOperation = "source-over";

        const lineRate = Math.max(2, window.devicePixelRatio) / 2;

        //mawari
        ctx.beginPath();
        ctx.lineWidth = 1.5 * lineRate; //antialiasing Lines
        ctx.fillStyle = "#ffffff";
        ctx.moveTo(p * 0, p * 0.29);
        ctx.lineTo(p * 0.29, p * 0.29);
        ctx.lineTo(p * 0.29, p * 0);
        ctx.lineTo(p * 0.5, p * 0.26);
        ctx.lineTo(p * 0.71, p * 0);
        ctx.lineTo(p * 0.71, p * 0.29);
        ctx.lineTo(p * 1, p * 0.29);
        ctx.lineTo(p * 0.76, p * 0.5);
        ctx.lineTo(p * 1, p * 0.71);
        ctx.lineTo(p * 0.71, p * 0.71);
        ctx.lineTo(p * 0.71, p * 1);
        ctx.lineTo(p * 0.5, p * 0.76);
        ctx.lineTo(p * 0.29, p * 1);
        ctx.lineTo(p * 0.29, p * 0.71);
        ctx.lineTo(p * 0, p * 0.71);
        ctx.lineTo(p * 0.24, p * 0.5);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        //naname1
        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.moveTo(p * 0.29, p * 0.29);
        ctx.lineTo(p * 0.71, p * 0.71);
        ctx.closePath();
        ctx.stroke();

        //naname2
        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.moveTo(p * 0.71, p * 0.29);
        ctx.lineTo(p * 0.29, p * 0.71);
        ctx.closePath();
        ctx.stroke();

        //tate
        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.moveTo(p * 0.5, p * 0.26);
        ctx.lineTo(p * 0.5, p * 0.76);
        ctx.closePath();
        ctx.stroke();

        //yoko
        ctx.beginPath();
        ctx.lineWidth = 1.5;
        ctx.moveTo(p * 0.24, p * 0.5);
        ctx.lineTo(p * 0.76, p * 0.5);
        ctx.closePath();
        ctx.stroke();

        ctx.globalCompositeOperation = !this.animEnd1 ? "destination-out" : "destination-in";

        ctx.beginPath();
        ctx.arc(p * 0.5, p * 0.5, p * this.r, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
    }
}
