import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor(flowers) {
        this.flowers = flowers;
        this.init();
    }

    init() {
        window.addEventListener("flowerSetted", this.setScrollTrigger.bind(this));
    }

    setScrollTrigger() {
        const revealElm = [...document.querySelectorAll(".js-scrollreveal")];

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 85%",
                //markers: true,
                onEnter: () => {
                    el.setAttribute("data-reveal", "1");

                    if (el.getAttribute("data-flowerreveal")) {
                        const id = el.getAttribute("data-id");

                        this.flowers.forEach((flower) => {
                            if (flower.id == id) {
                                flower.animate();
                            }
                        });
                    }
                },
            });
        });
    }
}
