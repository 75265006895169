const setVariables = () => {
    let gridBase = 120;
    let innerScale = 10;
    let widthClass = "";

    const setGrid = () => {
        if (window.innerWidth >= 2400) {
            gridBase = window.innerWidth / 20;
            widthClass = "grid_exlarge";
        } else if (window.innerWidth >= 1800) {
            gridBase = 120;
            widthClass = "grid_large";
        } else if (window.innerWidth >= 1600) {
            gridBase = 110;
            widthClass = "grid_normal";
        } else if (window.innerWidth >= 1200) {
            gridBase = 100;
            widthClass = "grid_normal";
        } else if (window.innerWidth >= 835) {
            gridBase = 75;
            widthClass = "grid_small";
        } else {
            gridBase = window.innerWidth / 14;
        }

        innerScale = window.innerWidth >= 835 ? 10 : 12; //インナー計算用

        document.body.style.setProperty("--grid", `${gridBase}px`);
        document.body.style.setProperty("--innerWidth", `${gridBase * innerScale}px`);
        document.body.setAttribute("data-widthclass", widthClass);
    };

    const setGridSurplus = () => {
        let surplus = window.innerWidth % gridBase;

        if (surplus > 0) {
            surplus = surplus - gridBase;
        }

        document.body.style.setProperty("--grid_surplus", `${surplus / 2}px`);
    };

    const setCorner = () => {
        let corner = window.innerWidth >= 835 ? 20 : window.innerWidth / 12 / 2;

        document.body.style.setProperty("--corner", `${corner}px`);
        document.body.style.setProperty("--corner_small", `${corner / 2}px`);
    };

    const setLineHeightFix = () => {
        const ua = navigator.userAgent;
        if (/android/i.test(ua)) {
            document.body.style.setProperty("--lineHeightFix", `10%`);
        }
    };

    window.addEventListener("resize", () => {
        setGrid();
        setGridSurplus();
        setCorner();
    });

    //Grid Set Event
    const gridSettedEvent = new CustomEvent("gridSetted");

    //初期化
    setGrid();
    setGridSurplus();
    setCorner();
    setLineHeightFix();

    //計算終了後にイベント起動
    setTimeout(() => {
        window.dispatchEvent(gridSettedEvent);
    }, 100);
};

export { setVariables };
