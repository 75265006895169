const ready = () => {
    document.body.setAttribute("data-ready", "1");

    setTimeout(() => {
        const headFlower = [...document.querySelectorAll(".l-subpageheader__flower")];
        headFlower.forEach((flower, index) => {
            setTimeout(() => {
                flower.setAttribute("data-floweranim", "1");
            }, index * 100);
        });
    }, 100);

    window.addEventListener("gridSetted", () => {
        document.body.setAttribute("data-gridsetted", "1");
    });
};

export { ready };
