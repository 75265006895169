const gnav = () => {
    const trigger = [...document.querySelectorAll(".js-gnavopen")];
    const menuItem = [...document.querySelectorAll(".js-gnavitem")];

    trigger.forEach((el) => {
        el.addEventListener("click", (e) => {
            if (document.body.getAttribute("data-gnavactive") === "1") {
                document.body.setAttribute("data-gnavactive", "0");
            } else {
                document.body.setAttribute("data-gnavactive", "1");
            }
        });
    });

    menuItem.forEach((el) => {
        el.addEventListener("click", (e) => {
            document.body.setAttribute("data-gnavactive", "0");
        });
    });
};

export { gnav };
