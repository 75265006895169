//functions
import { modal } from "./functions/modal";
import { gnav } from "./functions/gnav.es6";
import { setVariables } from "./functions/setVariables.es6";
import { ready } from "./functions/ready.es6";

//classess
import Movie from "./classes/movie.es6";
import ScrollFunc from "./classes/scrollfunc.es6";
import Flower from "./classes/flower.es6";
import Grid from "./classes/grid.es6";
import HierarchicalSelect from "./classes/hierarchical_select.es6";

export default class Main {
    constructor() {
        this.hierarchicalSelect = new HierarchicalSelect();
        this.movie = new Movie();

        //Flowers And Grids
        this.flowers = [];
        this.scrollFunc = new ScrollFunc(this.flowers);
        this.grid = [];

        //Init
        this.init();
    }

    init() {
        this.setFuncs();
        this.setGrid();
        this.setFlowers();
    }

    setGrid() {
        const wrapper = [...document.querySelectorAll(".l-grid__container")];
        wrapper.forEach((item) => {
            const grid = new Grid(item);
            this.grid.push(grid);
        });
    }

    setFlowers() {
        const flowerElements = [...document.querySelectorAll(".js-flower")];

        flowerElements.forEach((el) => {
            const flower = new Flower(el);
            this.flowers.push(flower);
        });

        this.headerFlowersAnimate();

        const fse = new CustomEvent("flowerSetted");
        window.dispatchEvent(fse);

        window.addEventListener("resize", () => {
            this.flowers.forEach((flower) => flower.onResize());
        });
    }

    headerFlowersAnimate() {
        this.flowers.forEach((flower, index) => {
            if (flower.id && flower.id.indexOf("sh") !== -1) {
                setTimeout(() => {
                    flower.animate();
                }, 500 + 200 * index);
            }
        });
    }

    setFuncs() {
        modal();
        gnav();
        setVariables();
        ready();
    }
}
