export default class Grid {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.init();
    }

    init() {
        this.hline = [];
        this.vline1 = [];
        this.vline2 = [];
        this.setup();
        this.eventBind();
    }

    setup() {
        const gridBase = parseFloat(document.body.style.getPropertyValue("--grid"));
        const wrapper = this.wrapper;
        const wg = 50;
        const hg = 10;

        for (let i = 0; i < wg; i++) {
            const hline = document.createElement("div");
            hline.classList.add("l-grid__h");
            hline.setAttribute("data-index", i);
            hline.style.top = `${gridBase * 0.5 * i}px`;
            wrapper.appendChild(hline);
            this.hline.push(hline);
        }

        //左半分
        for (let i = 0; i < hg; i++) {
            const vline = document.createElement("div");
            vline.classList.add("l-grid__v");
            vline.setAttribute("data-index", i);
            vline.setAttribute("data-dir", "l");
            vline.style.left = `${window.innerWidth / 2 - gridBase * i}px`;
            wrapper.appendChild(vline);
            this.vline1.push(vline);
        }

        //右半分
        for (let i = 0; i < hg; i++) {
            const vline = document.createElement("div");
            vline.classList.add("l-grid__v");
            vline.setAttribute("data-index", i);
            vline.setAttribute("data-dir", "r");
            vline.style.left = `${window.innerWidth / 2 + gridBase * i}px`;
            wrapper.appendChild(vline);
            this.vline2.push(vline);
        }
    }

    eventBind() {
        window.addEventListener("resize", () => {
            this.rePosition();
        });
    }

    rePosition() {
        const gridBase = parseFloat(document.body.style.getPropertyValue("--grid"));
        this.hline.forEach((item, i) => {
            item.style.top = `${gridBase * 0.5 * i}px`;
        });
        this.vline1.forEach((item, i) => {
            item.style.left = `${window.innerWidth / 2 - gridBase * i}px`;
        });
        this.vline2.forEach((item, i) => {
            item.style.left = `${window.innerWidth / 2 + gridBase * i}px`;
        });
    }
}
